import { Placeholder } from '@components/Placeholder'
import Head from 'next/head'
import * as React from 'react'

function NotFoundError(props) {
  return (
    <>
      <Head>
        <link rel="shortcut icon" type="image/png" href="/icon.png" />
      </Head>
      <Placeholder emoji="🚨" text="404 Error" />
    </>
  )
}

export default NotFoundError
